import { useEffect, useState, useContext } from 'react';
import { GetServerSideProps } from 'next';
import getConfig from 'next/config';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { gsspWithNonce } from "@next-safe/middleware/dist/document";

import { GetIdfromSlug, HandleLink, HandleType } from '../lib/utilities';
import { GQLHandler, IDefaultData } from '../lib/gqlHelper';
import { HandleMenuCookie } from '../lib/cookiesHelper';
import { MenuContext } from '../components/Layout';
import { IBioContent, IProjects, ILink, Colors, CraftPageType, IMenu } from '../components/common';
import { IBasicImage } from '../components/BasicImage';

const Loader = dynamic(() => import('../components/Loader'), {
  loading: () => <p>....Loading.....</p>,
});

const MainImage = dynamic(() => import('../components/MainImage'), {
  loading: () => <Loader isLoading />,
});

const TitleBox = dynamic(() => import('../components/TitleBox'), {
  loading: () => <Loader isLoading />,
});

const FancyContent = dynamic(() => import('../components/Layout/fancyContent'), {
  loading: () => <Loader isLoading />,
});

const { publicRuntimeConfig } = getConfig();

interface IPages {
  entryId:number;
  data:IDefaultData;
};

const Pages = ({ data, entryId }:IPages) => {

  const [pageData, setPageData] = useState<IDefaultData | null>(data);
  const [projectData, setProjectData] = useState<IProjects[] | null>(null);
  const [bioContentData, setBioContentData] = useState<IBioContent[] | null>(null);
  const [isCategory, setIsCategory] = useState<boolean>(false);
  const [isGrandParentCategory, setIsGrandParentCategory] = useState<boolean>(false);
  const [bodyData, setBodyData] = useState<string>('');
  const [bodyImage, setBodyImage] = useState<IBasicImage>();
  const [show404, setShow404] = useState<boolean>(false);
  const [craftType, setCraftType] = useState<CraftPageType>();

  const Cleanup = () => {
    setShow404(false);
    setPageData(null);
    setProjectData(null);
    setBodyData('');
    setBodyImage(undefined);
    setBioContentData(null);
    setCraftType(undefined);
    setIsCategory(false);
    setIsGrandParentCategory(false);
  };

  const BioContent = (bioContentData:IBioContent[]):JSX.Element =>{
    const Bio = dynamic(() => import('../components/Bio'), {
      loading: () => <Loader isLoading />,
    });

    return <Bio content={bioContentData} />
  };

  const ProjectContent = (projectData:IProjects[]):JSX.Element =>{
    const Projects = dynamic(() => import('../components/Projects'), {
      loading: () => <Loader isLoading />,
    });
    return <Projects projects={projectData} />
  };

  interface IBodyContent{
    bodyData:string;
    craftType:CraftPageType | undefined;
    bodyImage:IBasicImage | undefined;
    isCategory:boolean;
    isGrandParentCategory:boolean;
    entryId:number;
  };

  const BodyContent =(data:IBodyContent):JSX.Element =>{ 
    const {bodyData,craftType,bodyImage,isCategory,isGrandParentCategory,entryId} = data;
    const Content = dynamic(() => import('../components/Content'), {
      loading: () => <Loader isLoading />,
    });

    const ChildrenCategories = dynamic(() => import('../components/ChildrenCategories'), {
      loading: () => <Loader isLoading />,
    });

    return (<Content copy={bodyData} image={craftType === CraftPageType.SideBySide ? bodyImage : undefined}>
            <>
              {isCategory || isGrandParentCategory ? (
                <ChildrenCategories
                  isGrandParentCategory={isGrandParentCategory}
                  isCategory={isCategory}
                  currentId={entryId}
                  image={craftType === CraftPageType.Default ? bodyImage : undefined}
                />
              ) : undefined}
            </>
          </Content>);
  };

  const LearnMoreContent = (learnMore:ILink,menuCtx:IMenu[]):JSX.Element =>{
    const Button = dynamic(() => import('../components/Button'), {
      loading: () => <Loader isLoading />,
    });

    return <Button
      label="Learn More"
      position="left"
      type={HandleType(learnMore)}
      url={HandleLink(learnMore, menuCtx)}
      color={Colors.Blue}
    />
  };

  const Show404 =():JSX.Element =>{
    const C404 = dynamic(() => import('../components/C404'), {
      loading: () => <Loader isLoading />,
    });
    return <C404 />
  };

  const Builder = (defaultData: IDefaultData) => {
    if (defaultData.projects !== undefined && defaultData.projects.length > 0) {
      setProjectData(defaultData.projects);
    }

    if (defaultData.bioContent !== undefined && defaultData.bioContent.length > 0) {
      setBioContentData(defaultData.bioContent);
    }

    if (defaultData.body !== undefined && defaultData.body !== '') {
      setBodyData(defaultData.body);
    }

    if (defaultData.isParentCategory !== undefined && defaultData.isParentCategory) {
      setIsCategory(true);
    }

    if (defaultData.isGrandParentCategory !== undefined && defaultData.isGrandParentCategory) {
      setIsGrandParentCategory(true);
    }

    if (defaultData.typeHandle !== undefined) {
      setCraftType(defaultData.typeHandle);
    }

    if (defaultData.image !== undefined && defaultData.image.length > 0) {
      setBodyImage(defaultData.image[0]);
    }

    if (defaultData !== undefined) {
      setPageData(defaultData);
    }
  };

  const Load404 = () => {
    setPageData({
      id: 0,
      title: ' 404 Page not Found !',
      mainImage: [
        {
          large: `${publicRuntimeConfig.CRAFTCMSASSETSURL}/images/main/_formatmaininage/36/page-header-about.webp`,
          small: `${publicRuntimeConfig.CRAFTCMSASSETSURL}/images/main/_cropAndFormatMainImage400x230/36/page-header-about.webp`,
          medium: `${publicRuntimeConfig.CRAFTCMSASSETSURL}/images/main/_cropAndFormatMainImage800x360/36/page-header-about.webp`,
          title: '404 Page not Found',
          id: 99,
        },
      ],
    });
    setShow404(true);
  };

  useEffect(() => {
    Cleanup();
    if(data !== null){
     Builder(data);
    } else{
      Load404();
    }
  }, [data]);

  const menuCtx = useContext(MenuContext);
  const hasLearnMore =
    pageData?.learnMore !== undefined && pageData.learnMore[0]?.id !== undefined && pageData.learnMore[0]?.id !== null;
  let learnMore: ILink[] = [];

  if (hasLearnMore) {
    learnMore = pageData?.learnMore as unknown as ILink[];
  }

  return (
    <>
      {pageData !== null ? (
        <>
          <Head>
            <title>{pageData?.title}</title>
          </Head>
          <MainImage isHomepage={false} image={pageData?.mainImage?.[0]}>
            <TitleBox isHomepage={false} title={pageData?.title ?? ''} />
          </MainImage>
          <FancyContent>
            <>
              {!show404 ? (
                <>
                  {projectData !== null && projectData.length > 0 && ProjectContent(projectData) }
                  {bioContentData !== null && bioContentData.length > 0 && BioContent(bioContentData)}
                  {bodyData !== '' && BodyContent({bodyData, craftType, bodyImage, isCategory, isGrandParentCategory, entryId})}
                  {hasLearnMore && learnMore[0].url !== null && LearnMoreContent(learnMore[0],menuCtx)}
                </>
              ) : (
                Show404()
              )}
            </>
          </FancyContent>
        </>
      ) : (
        <Loader isLoading />
      )}
    </>
  );
};
export default Pages;

export const getServerSideProps: GetServerSideProps = gsspWithNonce(async (context) =>{
  
  const qs = context.params?.pages as unknown as [];
  const menu:IMenu[]|null= await HandleMenuCookie(context);
  let entryId:number = 0;
  let defaultData:IDefaultData|null = null;
  if(menu !== null){
    entryId = GetIdfromSlug(menu as unknown as IMenu[], qs);
    const entry = await GQLHandler('pages', '', entryId);
    defaultData = entry?.entry as unknown as IDefaultData;
  }

  return {props:{data:defaultData,entryId:entryId}};
});